<template>
	<div id="app">
		<pageheader v-show="showPageHeader"></pageheader>
		<div style="min-height: 1000px"><router-view /></div>
		<pageFooter></pageFooter>
	</div>
</template>
<script>
	import cookieUtil from "@/util/cookieUtil"
	import {
		getParam
	} from '@/util/base';
	import pageFooter from '@/components/pageFooter'
	import pageheader from '@/components/pageHeader'
	export default {
		name: "index",
		components: {
			pageFooter,
			pageheader
		},
		data() {
			return {
				showPageHeader: false
			}
		},
		mounted() {
			if (getParam('JSESSIONID')) {
				cookieUtil.setCookie({
					key: 'JSESSIONID',
					value: getParam('JSESSIONID'),
					expires: 120
				})
			}
		},
		watch: {
			$route(to, from) {
				this.showPageHeader = !(to.path.indexOf('userCenter') > -1 || to.path.indexOf('netMessage') > -1 || to.path
					.indexOf('xiaoxi') > -1)
			}
		}
	}
</script>
<style lang="scss">
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		/*color: #2c3e50;*/
		/*max-width: 1920px;*/
		min-width: 1200px;
		margin: auto;
		min-height: 100vh;

		@font-face {
			font-family: fangsong;
			src: url("./assets/ttf/fangsong_GB2312.ttf");
		}
	}
</style>