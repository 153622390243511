import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import api from './api'

import {
	resetForm,
} from "@/util/base";
import
baseConfig
from "@/util/config";
Vue.prototype.$api = api;
// console.log(baseConfig)
Vue.prototype.$toLogin = function(callback) {
	location.href = `https://www.tzjsyxw.cn/tzapi/user/nohello?callback=${callback}`
	// location.href = `https://www.tzjsyxw.cn/tzapi/user/nohello?callback=${callback}`
	// location.href = `http://1.13.9.245/tzapi/user/nohello?callback=${callback}`
}
Vue.config.productionTip = false
// 全局方法挂载
Vue.prototype.resetForm = resetForm
Vue.prototype.baseConfig = baseConfig
// 分页组件
import Pagination from "@/components/Pagination";
// 全局组件挂载
Vue.component('Pagination', Pagination)
Vue.use(ElementUI)

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')