import Cookies from 'js-cookie';

function setCookie(data) {
  //data.expires单位为minutes
  let inFifteenMinutes = new Date(new Date().getTime() + data.expires * 60 * 1000);
  Cookies.set(data.key, data.value, {expires: inFifteenMinutes});
}

function getCookie(key) {
  return Cookies.get(key)
}

function delCookie(key) {
  Cookies.remove(key);
}

export default {
  setCookie,
  getCookie,
  delCookie
};
