<template>
  <div class="page-footer-container">
    <img src="../assets/Bottom.png" alt="">
    <!--<img class="home-bj" src="../assets/bj1.png" alt="">-->
    <div class="desc-div">
      <div class="desc-item">主办单位:通州区教师研修中心</div>
      <div class="desc-item">|</div>
      <div class="desc-item">地址:北京市通州区东关上园175号</div>
      <div class="desc-item">|</div>
      <div class="desc-item">电话:010-52113016</div>
    </div>
    <div class="desc-div">
      <!--<div class="desc-item">技术支持:中国教师研修网</div>-->
    </div>
    <div class="desc-div">
      <div class="desc-item">京ICP备16038217号-2 电信与信息服务业务许可证</div>
    </div>
    <div class="desc-div">
      <div class="desc-item">@版权所有 2016 通州教师研修网           京公网安备 11011202001809号</div>
    </div>

  </div>
</template>

<script>
  export default {
    name: "pageFooter"
  }
</script>

<style lang="scss" scoped>
.page-footer-container {
  width: 100%;
  min-width: 1200px;
  margin: auto;
  position: relative;
  font-size: 12px;
  padding: 35px 0 0;
  img {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
  .home-bj {
    width: 547px;
    height: 552px;
    position: absolute;
    top: -552px;
    left: -220px;
  }
  .desc-div {
    color: #fff;
    min-width: 1200px;
    margin: 8px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99!important;
    .desc-item {
      z-index: 9;
      padding: 0 12px;
    }
  }
}
</style>