import Axios from "axios";
import qs from "qs";
import cookieUtil from "./cookieUtil";
import
baseConfig
from "@/util/config";
const axios = Axios.create();
import {
	Message
} from 'element-ui'
// console.log(baseConfig)
if (process.env.NODE_ENV === "development") {
	axios.defaults.baseURL = "/tzapi";
} else if (process.env.NODE_ENV === "production") {
	// axios.defaults.baseURL = baseConfig.baseApiUrl;
	axios.defaults.baseURL = `https://${location.host}/tzapi`;
}

axios.interceptors.request.use(config => {
	//needCookie 接口是否需要登录状态下的cookie
	let needCookie = config.needCookie || false
	// let token = JSON.parse(cookieUtil.getCookie("m-userInfo") || "{}").token;
	// config.headers["token"] = token || "";
	let current_cookie = cookieUtil.getCookie("JSESSIONID") || ''
	if (current_cookie) {
		//cookie有效期为120分钟
		cookieUtil.delCookie('JSESSIONID')
		cookieUtil.setCookie({
			key: 'JSESSIONID',
			value: current_cookie,
			expires: 120
		})
	} else if (needCookie) {
		location.href = process.env.NODE_ENV === 'development'
		  ? `http://localhost:8888/tzapi/user/nohello?callback=http://localhost:8888/`
		  : `https://www.tzjsyxw.cn/tzapi/user/nohello?callback=https://www.tzjsyxw.cn/`
		return;
	}
	if (config.json) {
		if (!config.array) {
			config.data = qs.parse(config.data);
		}
		config.headers = Object.assign(config.headers || {}, {
			"Content-Type": "application/json",
			'Access-Control-Allow-Origin': location.origin
		});
	} else {
		//如果不是formdata数据
		if (Object.prototype.toString.call(config.data) != "[object FormData]") {
			config.data = qs.stringify(config.data);
			config.headers = Object.assign(config.headers || {}, {
				"Content-Type": "application/x-www-form-urlencoded"
			});
		}
	}
	return config;
});

axios.interceptors.response.use(
	response => {
		if (response.data.code == 200) {
			return qs.parse(response.data);
		} else if (response.data.code == 301) {
			// window.location.href = "/manager/login";
		} else {
			Message.error(response.data.msg);
			return response.data;
		}
	},
	error => {
		return Promise.reject(error);
	}
);
export default axios;