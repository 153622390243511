<template>
  <div class="page-header-container">
    <div style="width: 1200px;margin: auto;padding-top: 30px;text-align: left">
      <img src="../assets/logo2.png" style="width: 166px;display: inline-block" alt="">
      <!-- <img src="../assets/logo1.png" style="width: 400px;display: inline-block;margin-left: 200px;vertical-align: top;margin-top: 20px" alt=""> -->
    </div>
    <!--<img class="bg-img" src="../assets/header-bg.png" alt="">-->
    <img class="home-bj" src="../assets/bj.png" alt="">
  </div>
</template>

<script>
  export default {
    name: "pageHeader"
  }
</script>

<style lang="scss" scoped>
.page-header-container {
  width: 100%;
  height: 15vw;
  min-height: 200px;
  max-height: 222px;
  position: relative;
  .logo1 {
    position: absolute;
    left: 6vw;
    top: 8vw;
    z-index: 2;
  }
  .logo2 {
    width: 90px;
    position: absolute;
    left: 15vw;
    top: 1vw;
    z-index: 2;
  }
  .bg-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    z-index: 0;
  }
  .home-bj {
    position: absolute;
    right: 0;
    bottom: -350%;
    z-index: -1;
  }
}
</style>